<template>
  <v-menu
    v-model="openMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        :error-messages="errorMessages"
        append-icon="event"
        outlined
        readonly
        dense
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        @blur="$emit('blur')"
        @input="$emit('input')"
      />
    </template>
    <v-date-picker v-model="date" :min="min" :max="max" @input="openMenu = false" />
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon'
import { Timestamp } from '@/services/firebase'

export default {
  name: 'DateField',
  props: {
    value: { type: [Object, String], default: null },
    timezone: { type: String, required: true },
    label: { type: String, default: '' },
    errorMessages: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    min: { type: String, default: null },
    max: { type: String, default: null },
  },
  data() {
    return {
      openMenu: false,
      date: null,
    }
  },
  computed: {
    dateFormatted({ date }) {
      return date ? DateTime.fromISO(date).toLocaleString() : ''
    },
  },
  watch: {
    date(val) {
      this.$emit('input', val)
    },
    value: {
      immediate: true,
      handler(val) {
        this.date = val instanceof Timestamp ? DateTime.fromSeconds(val.seconds, { zone: this.timezone }).toISODate() : val
      },
    },
  },
}
</script>
